import { STATICS } from 'statics'

export const OPTIONS = [
  {
    Icon: STATICS.FAQ_UP_VOTE,
    voteId: 'UP',
    isPositive: true,
    text: 'Sim',
  },
  {
    Icon: STATICS.FAQ_DOWN_VOTE,
    voteId: 'DOWN',
    isPositive: false,
    text: 'Não',
  },
]
