/* eslint-disable react/no-danger */
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setDrawerAlert } from 'slices/drawer'
import { STATICS } from 'statics'
import { sendFaqVote } from 'hooks'
import {
  FAQ_VOTE_ERROR,
  FAQ_VOTE_SUCCESS,
  OPTIONS,
} from 'components/drawer/templates/faq/constants'
import './style.scss'

export function Accordion({ title = '', description = '', id }) {
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState('')
  const dispatch = useDispatch()
  const contentHeight = useRef()

  async function handleClickVote(isPositive, voteId) {
    try {
      await sendFaqVote(id, isPositive)

      setSelected(voteId)
      dispatch(setDrawerAlert({ severity: 'success', title: FAQ_VOTE_SUCCESS }))
    } catch (error) {
      console.error(error)

      dispatch(setDrawerAlert({ severity: 'error', title: FAQ_VOTE_ERROR }))
    }
  }

  function renderVotes() {
    return OPTIONS.map(({ Icon, voteId, text, isPositive }) => {
      const isSelected = selected === voteId

      return (
        <button
          id={voteId}
          onClick={() => handleClickVote(isPositive, voteId)}
          type='button'
          className={`vote ${isSelected ? 'selected' : ''}`}
          disabled={!!selected}
        >
          <Icon />
          {text}
        </button>
      )
    })
  }

  function renderReview() {
    return (
      <div className='container-review'>
        <span className='text'>Essa informação foi útil para você?</span>
        <div className='container-votes'>{renderVotes()}</div>
      </div>
    )
  }

  return (
    <div className={`accordion-component ${isOpen && '-open'}`}>
      <button className='header-button' type='button' onClick={() => setIsOpen(!isOpen)}>
        {title}
        <div className='icon'>
          <STATICS.ARROW_DOWN />
        </div>
      </button>
      <div
        className='container-answer body-content'
        ref={contentHeight}
        style={{
          height: isOpen ? contentHeight.current.scrollHeight : '0px',
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: description }} />
        {renderReview()}
      </div>
    </div>
  )
}
