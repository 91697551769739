import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { EVENTS } from 'app-constants'
import { postEvent } from 'hooks'
import { readMessage } from 'slices/customer'
import { setModal } from 'slices/modal'
import { formatDate, getFirstName, getMassiveMessage } from 'utils'
import { STATICS } from 'statics'
import { Card } from 'components/card'
import { Button } from 'components/button'
import './style.scss'

export function MessagesModal() {
  const { push } = useHistory()
  const dispatch = useDispatch()
  const [message, setMessage] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [items, setItems] = useState({})

  const shouldOpenContactModal = useSelector(state => state.modal.shouldOpenContactModal)
  const { messages, info } = useSelector(({ customer }) => customer)
  const {
    virtualSupportMassive: { message: massiveWarning },
  } = useSelector(({ massive }) => massive)
  const idContract = useSelector(state => state.contract.selected.id)

  function handleClick() {
    push('/')

    if (shouldOpenContactModal) dispatch(setModal({ key: 'contact_update' }))
    else dispatch(setModal({ key: '' }))
  }

  useEffect(() => {
    const array = []

    if (massiveWarning) {
      postEvent(idContract, EVENTS.ABNORMALITY_WARNING)
      array.push(getMassiveMessage(massiveWarning))
    }

    if (messages?.contentUnread && !modalOpen) {
      messages.contentUnread.forEach(element => {
        if (element.mandatory) {
          array.push(element)
        }
      })

      setModalOpen(true)
      setItems(array)
    }
  }, [messages.contentUnread, modalOpen, idContract, massiveWarning])

  useEffect(() => {
    const massiveWarnings = {
      ...items[0],
      index: 0,
    }
    setMessage(massiveWarnings)
  }, [items])

  useEffect(() => {
    const { id, visualized } = message
    if (id && !visualized) {
      dispatch(readMessage(id))
    }
  }, [message, dispatch])

  function handleChange(index) {
    const newMessage = {
      ...items[index],
      index,
    }
    setMessage(newMessage)
  }

  return (
    <div className='message-modal'>
      <h3 className='title'>
        Olá <span className='name'>{getFirstName(info.name)}</span>, você tem novas mensagens!
      </h3>
      <div className='message-details-component-modal'>
        <Card className='card'>
          <p className='title'>{message.title}</p>
          {message.imageUrl && <img alt={message.title} src={message.imageUrl} />}
          <p className='text description'>{message.description}</p>
          <div className='box-link'>
            {message.launchUrl && (
              <a
                className='link'
                rel='noopener noreferrer'
                href={message.launchUrl}
                target='_blank'
              >
                {' '}
                {message.launchUrl}
              </a>
            )}
          </div>
          <div className='box'>
            <p className='date text'>{formatDate(message.creationDateTime)}</p>
          </div>
        </Card>
        {items.length > message.index + 1 && (
          <div>
            <STATICS.ARROW_RIGHT className='icon' onClick={() => handleChange(message.index + 1)} />
          </div>
        )}
      </div>
      {items.length > message.index + 1 ? (
        <Button
          className='button'
          color='primary'
          size='large'
          onClick={() => handleChange(message.index + 1)}
        >
          Ok, próximo
        </Button>
      ) : (
        <Button className='button' color='primary' size='large' onClick={handleClick}>
          Voltar a home
        </Button>
      )}
    </div>
  )
}
